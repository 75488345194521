import { OrtoScene } from "../types";

type PotreeTools = {
  [key: string]: (viewer?: any, ortoScene?: OrtoScene) => void;
};

export const potreeTools: PotreeTools = {
  addAngleMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: false,
      showAngles: true,
      showArea: false,
      closed: true,
      maxMarkers: 3,
      name: "Angle",
    });
  },

  addPointMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: false,
      showAngles: false,
      showCoordinates: true,
      showArea: false,
      showElevation: true,
      closed: true,
      maxMarkers: 1,
      name: "Point",
    });
  },

  addDistanceMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: true,
      showArea: false,
      showHeight: false,
      closed: false,
      name: "Distance",
    });
  },

  addHeightMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: false,
      showHeight: true,
      showArea: false,
      closed: false,
      maxMarkers: 2,
      name: "Height",
    });
  },

  addCircleMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: false,
      showHeight: false,
      showArea: false,
      showCircle: true,
      showEdges: false,
      closed: false,
      maxMarkers: 3,
      name: "Circle",
    });
  },

  addAzimuthMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: false,
      showHeight: false,
      showArea: false,
      showCircle: false,
      showEdges: false,
      showAzimuth: true,
      closed: false,
      maxMarkers: 2,
      name: "Azimuth",
    });
  },

  addAreaMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: true,
      showArea: true,
      closed: true,
      name: "Area",
    });
  },

  addHorizontalMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: false,
      showHorizontalDist: true,
      showArea: false,
      closed: false,
      maxMarkers: 2,
      name: "Horizontal distance",
    });
  },

  addRemoveMeasurements: (viewer) => {
    let scene = viewer.scene;
    while (scene.measurements.length > 0) {
      scene.removeMeasurement(scene.measurements[0]);
    }
  },

  showModel: (viewer) => {
    viewer.showBoundingBox = false;
    viewer.useEDL = true;
    viewer.setBackground("white");
    viewer.useHQ = true;
    viewer.setCameraMode(1);
    viewer.scene.volumes[0].visible = true;
    viewer.setControls(viewer.orbitControls);
    // viewer.setFrontView();

    for (let i = 0; i < viewer.scene.pointclouds.length; i++) {
      const material = viewer.scene.pointclouds[i].material;
      material._shape = 1;
      material.minSize = 0.1;
      material.opacity = 1;
      material.rgbGamma = 1;
      material.rgbBrightness = 0;
      material.rgbContrast = 0;
    }
  },

  setOrthogonalView: (viewer) => {
    viewer.showBoundingBox = false;
    viewer.useEDL = false;
    viewer.setBackground("black");
    viewer.useHQ = false;
    viewer.setCameraMode(0);
    viewer.scene.volumes[0].visible = false;
    viewer.setControls(viewer.planeControls);

    for (let i = 0; i < viewer.scene.pointclouds.length; i++) {
      const material = viewer.scene.pointclouds[i].material;
      material._shape = 1;
      material.minSize = 1;
      material.opacity = 0.01;
      material.rgbGamma = 1.44;
      material.rgbBrightness = 0.6;
      material.rgbContrast = 1;
    }
  },

  showGrundriss: (viewer) => {
    potreeTools.setOrthogonalView(viewer);
    // viewer.setTopView();
  },

  showSchnitt: (viewer) => {
    potreeTools.setOrthogonalView(viewer);
    // viewer.setFrontView();
  },

};
